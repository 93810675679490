.table-export-button-excel {
    background-color: #009800;
    color: #FFFFFF;
}

.table-export-button-excel:hover {
    background-color: #52d352;
    color: #FFFFFF;
}

.table-responsive::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table-responsive {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.dataTables_wrapper .dataTables_paginate>.paginate_button {
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    border-radius: 50% !important;
    padding: 0;
    font-size: 1rem;
}

.sorting-table-container {
    overflow-x: scroll;
}

.sorting-table-container::-webkit-scrollbar {
    height: 1.25rem !important;
    background-color: var(--white);
    border: 1px solid var(--primary);
    border-radius: 0.25rem;
    ;
}

::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px var(--shadow-black);  */
    border-radius: 0.25rem;
}

.sorting-table-container::-webkit-scrollbar-thumb {
    height: 0.5rem !important;
    background-color: var(--primary);
    border: 1px solid var(--white);
    border-radius: 0.25rem;
    cursor: pointer;
}

.sorting-table-container::-webkit-scrollbar-thumb:hover {
    box-shadow: inset 0 0 5px #2b2b2b6d;
}

.color-box {
    border-radius: 0.75em;
    padding: 5px 7px;
    background-color: var(--color-bg);
    color: white;
}

.icon-box {
    width: 4em;
    height: 4em;
    border-radius: 0.75em;
    border: 1px solid #909090;
}

.profile-image-box {
    width: 6em;
    height: 6em;
    border-radius: 0.75em;
    border: 1px solid #909090;
}

.profile-image-box>img {
    border-radius: inherit;
}

.color-box-input {
    height: 5em;
    width: 5em;
    padding: 1em;
}

.error {
    color: red;
}

.h-25-vh {
    height: 25vh !important;
}

.h-50-vh {
    height: 50vh !important;
}

.h-75-vh {
    height: 75vh !important;
}

.h-100-vh {
    height: 100vh !important;
}

/* special buttons */
.cancel-button-icon {
    color: #ff0000;
}

.cancel-button-icon:hover {
    color: #ff000073;
}

.move-up-button-icon,
.move-down-button-icon {
    color: #7b7b7b;
    cursor: pointer;
}

.move-up-button-icon:hover,
.move-down-button-icon:hover {
    color: #bcbcbc
}

/* font larger */
.font-25-bigger {
    font-size: 1.25em;
}

/* required for fontawesome */

.deznav .metismenu>li>a svg {
    max-width: 1.5rem;
    max-height: 1.5rem;
    height: 100%;
    margin-right: 1.25rem;
    color: #BFBFBF;
}

.deznav .metismenu>li.mm-active>a {
    color: #fff;
    font-weight: 600;
    box-shadow: none;
    background: var(--primary);
    box-shadow: 0rem 0.75rem 1.75rem var(--rgba-primary-3);
}

[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a svg {
    color: #fff !important;
    font-weight: 300;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a svg {
    color: #fff;
}

/* Force bottom row filter section to align center with removing preset margin */
/* .MuiTablePagination-displayedRows,
.MuiTablePagination-selectLabel {
    margin: 0;
} */

.invert-text-color {
    background: inherit;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    filter: invert(1) grayscale(1);
    -webkit-filter: invert(1) grayscale(1);
}

.box-color {
    width: 0.25rem;
    height: 0.25rem;
    background-color: transparent;
}

.input-phone-group {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 0.75rem;

}

.hover-button-red:hover {
    color: red !important;
}

.font-bigger {
    font-size: large;
}

.header-border {
    border: 1px solid #6e6e6e !important;
    min-width: 120px;
}

.modal-box {
    min-width: 50vw;
    min-height: 60vh;
}

* .btn {
    border-radius: 0.25rem !important;
}

.accordion-summary-border-bottom {
    border-bottom: 1px solid #ececec;
}

.entry-list-view-container {
    height: 55vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.entry-list-item {
    border: 1px solid #ececec;
    cursor: pointer;
    padding: 15px;
}

.entry-list-item.selected {
    background-color: #b3d6ff77;
}

.loader {
    width: 48px;
    height: 48px;
    border: 3px dotted #000;
    border-style: solid solid dotted dotted;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 2s linear infinite;
  }
  .loader::after {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px dotted #FF3D00;
    border-style: solid solid dotted;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    animation: rotationBack 1s linear infinite;
    transform-origin: center center;
  }
      
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 
  @keyframes rotationBack {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  } 
